<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/plot`"
                title="Registerd Plot"
                :defaultParams="{status:'Registered'}"
                :columns="columns"
                routerpath="/legal/saleslegal"
                :formOptions="formOptions"
                :noAddButton="true"
                :canDelete="0"
                :noDelete="true"
                :noAction="true"
              >
              <template #customField='{props}'>

                  <span v-if="props.column.field === 'plotsize'">
                        {{ props.row.plotno ? (props.row.plotno.plotareayd) : '' }}
                        </span>
                        <span v-else-if="props.column.field === 'plotrate'">
                                {{ props.row.plotno.length > 0 ?
                                (props.row.team_data.length>0 &&props.row.team_data[0].role == 'franchise' ? props.row.plotno.franchiserate : props.row.plotno.rateperyd )
                                : '' }}
                        </span>
                        <span v-else-if="props.column.field === 'plotvalue'">
                              {{ props.row.plotno.length > 0 ?
                                (props.row.team_data.length>0 &&props.row.team_data[0].role == 'franchise' ? props.row.plotno.plotValueFr : props.row.plotno.plotValue )
                                : '' }}
                        </span>
                        <span v-else-if="props.column.field === 'customer' " class="cursor-pointer">
                          <div v-if="props.row.sales_data && props.row.sales_data.customer">
                            {{ props.row.sales_data.customer.fullname }}
                          </div>
                      </span>
                  </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
// import action from './action.vue'
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Attachment from "../../../components/Attechment.vue";
import axios from 'axios'
const baseApi = process.env.VUE_APP_APIENDPOINT;
import {
  BFormRadio,
  BModal,
  BFormRadioGroup,
  BFormGroup,
  BBadge,
  BTooltip,
  BFormTextarea,
  BFormFile,
  BFormInput,
  BRow,
  BCol,
  BButton
} from "bootstrap-vue";
import moment from 'moment'
export default {
  components: {
    GoodTableColumnSearch,
    BTooltip,
    BModal,
    Attachment,
    // action,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BBadge,
    flatPickr,
    BFormTextarea,
    BFormFile,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: "Plot ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search Plot ID",
          },
          hidden: true,
        },
        {
          label: "Plot No",
          field: "plotno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Plot No",
          },
        },
        {
          label: "Project",
          field: "project",
          searchField:'project_id',
          responseValue:'projectname',
          type: "dropdown",
          url: "getProject",
          filterOptions: {
            enabled: true,
            placeholder: "Search Project",
          },
        },
        {
          label: "Plot Area In Sq.Feet",
          field: "plotareasqfeet",
          filterOptions: {
            enabled: true,
            placeholder: "Search Area",
          },
        },
        {
          label: "Plot Value",
          field: "plotValue",
          filterOptions: {
            enabled: true,
            placeholder: "Search Value",
          },
        },

        {
          label: "Plot Amount",
          field: "plotamount",
          filterOptions: {
            enabled: true,
            placeholder: "Search Amount",
          },
        },
        {
          label: "Extra Amount",
          field: "extraamount",
          filterOptions: {
            enabled: true,
            placeholder: "Search Amount",
          },
        },
         {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: true,
            placeholder: "Search Remarks",
          },
        },
        {
          label: "Customer Name",
          field: "customer",
          filterOptions: {
            enabled: true,
            placeholder: "Search Customer",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          responseValue:'name',
          type: "staticdropdown",
          url:'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
         {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          responseValue:'name',
          type: "staticdropdown",
          url:'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
      ],
      formOptions: {
        submitRouterPath: "/setup/language",
        formTitle: "KYC",
        usersDropdown: true,
        method: "post",
        action: "add",
        gridForm: true,
        url: `${baseApi}/language`,
        inputFields: [],
      },
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
